@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
.body_section{
  padding: 20px 70px;
}


/*active class Header Section*/
.header_section a{
  width: 100%;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif !important;
}
.header_section a.active {
  color: black;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif !important;
}



/* table_section*/
.table_section .chakra-table__container{
  width: 100%;

}
.table_section .chakra-table__container .chakra-table{
  width: 100%;
  border: 1px solid #ccc;
  border-spacing: 0px;
}
.table_section .chakra-table__container .chakra-table thead{
  background-color: #F5F5F5;
  border-spacing: 0px;

}
.table_section .chakra-table__container .chakra-table thead tr th{
  border-spacing: 0px;
  text-transform:capitalize ;
  font-size: 14px;
  font-weight: 700;
  font-family:  'Open Sans', sans-serif !important;
  color: #000000;
  padding: 12px 15px;
  text-align: left;
   border-bottom: 1px solid #CCCCCC;

}
.table_section .chakra-table__container .chakra-table tbody tr td{
  border-spacing: 0px;
  font-size: 14px;
  font-weight: 400;
  font-family:  'Open Sans', sans-serif !important;
  color: #000;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}
.table_section .chakra-table__container .chakra-table tbody tr:last-child td{
/*  border-bottom: 0px;*/
}
.table_section .chakra-table__container .chakra-table tbody tr td a{
  color: #0085FF;
  text-decoration: none;
  text-align: left;
}
.table_section .chakra-table__container .chakra-table tbody tr td .table_section .chakra-table__container .chakra-table{
  border: 0px;
}
.table_section .chakra-table__container .chakra-table tbody tr td .table_section .chakra-table__container .chakra-table tbody tr:last-child td{
  border-bottom: 0px;
}

.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list{
  width: 200px;
}

.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list button{
  font-size: 12px;
  padding: 8px 15px;
  display: inline-block;
  height: unset;
}
.table_section .chakra-table__container .chakra-table tbody tr td .chakra-menu__menu-list hr{
  margin: 0px;
} 


/*inputt Csss*/
.field_section{
  margin-bottom: 20px;
}
.field_section_radio label {
  font-size: 14px;
  font-weight: 500;
  font-family: 'Lato', sans-serif;
  margin-bottom: 5px;
  display: block;
}


.field_section .chakra-form__label{
  font-size: 14px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
}
.field_section label{
  font-size: 12px;
  font-weight: 500;
  color: #000;
  margin-bottom: 5px;
  display: block;
}
.field_section .chakra-input{
  border: 1px solid #ccc;
  height: 43px;
  padding: 10px 15px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
.field_section .chakra-select{
  border: 1px solid #ccc;
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 15px;
  font-family: 'Lato', sans-serif;
}

.simple_button{
  border:1px solid #000000 ;
 background-color: #FFFFFF !important;
 font-size: 14px !important;
 color: #000000 !important;
 font-weight: 400 !important;
 padding: 10px 25px !important;
}


.chakra-menu__menu-button.menu-filter{
  border:1px solid #000000 ;
 background-color: #FFFFFF !important;
 font-size: 14px !important;
 color: #000000 !important;
 font-weight: 400 !important;
 padding: 10px 25px !important;
}

.chakra-menu__menu-button.menu-filter-button{
  border:1px solid #2BB673 ;
 background-color: #2BB673 !important;
 font-size: 14px !important;
 color: #FFFFFF !important;
 font-weight: 400 !important;
 padding: 10px 25px !important;
}


.chakra-button.filter-button{
  border:1px solid #000000 ;
 background-color: #FFFFFF !important;
 font-size: 14px !important;
 color: #000000 !important;
 font-weight: 400 !important;
 padding: 10px 25px !important;
}



.date_range {
  border: 1px solid #000000 !important;
  font-size: 14px !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  padding: 10px;
  background: white;
  cursor: pointer;
}

.date_range::after {
  content: '⌄';
  position: absolute;
  right: 2px;
  top: 45%;
  transform: translateY(-56%);
  font-size: 18px !important;
}

.date_range::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}




/*Setting Page Start Css*/

  .setting_page{
    width: 100%;
    display: flex;
    margin: 0px auto;
    padding: 20px 0px;
    align-items: flex-start;
  }
  .setting_page .setting_menu_section{
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    width: 20%;
    padding: 10px 15px;
    height: unset;
  }
  .setting_page .setting_menu_section .back_menu_section{
    background: #F9FAFA;
    border-bottom: 1px solid #CCCCCC;
    border-radius: 5px 5px 0px 0px;
  }
  .setting_page .setting_menu_section .back_menu_section a{
    display: flex;
    align-items: center;
    padding: 10px 25px;
  }
  .setting_page .setting_menu_section .back_menu_section a svg{
    font-size: 28px;
    color: #000000;
    margin-right: 15px;
  }
  .setting_page .setting_menu_section .back_menu_section a p{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #5C5F62;
    margin: 0px;
  }
  .setting_page .setting_menu_section .menu_section{
    margin: 5px 0px;
  }
  .setting_page .setting_menu_section .menu_section ul{
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }
  .setting_page .setting_menu_section .menu_section ul li{
    margin-bottom: 10px;
  }
  .setting_page .setting_menu_section .menu_section ul li a{
    display: flex;
    align-items: center;
    padding: 10px 30px;
    margin: 0px 5px;
  }
  .setting_page .setting_menu_section .menu_section ul li a .img_box{
    margin-right: 15px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .setting_page .setting_menu_section .menu_section ul li.active a .img_box .icon_img_active, .setting_page .setting_menu_section .menu_section ul li a:hover .img_box .icon_img_active{
    display: block;
  }
  .setting_page .setting_menu_section .menu_section ul li a .img_box .icon_img_active{
    display: none;
  }
  .setting_page .setting_menu_section .menu_section ul li.active a .img_box .icon_img, .setting_page .setting_menu_section .menu_section ul li a:hover .img_box .icon_img{
    display: none;
  }
  .setting_page .setting_menu_section .menu_section ul li a:hover, .setting_page .setting_menu_section .menu_section ul li.active a{
    background: #2BB67380;
    border-radius: 20px;
  }
  .setting_page .setting_menu_section .menu_section ul li a:hover svg, .setting_page .setting_menu_section .menu_section ul li.active a svg{
    color: #047E60;
  }
  .setting_page .setting_menu_section .menu_section ul li a:hover p, .setting_page .setting_menu_section .menu_section ul li.active a p{
    color: #047E60;
  }
  .setting_page .setting_menu_section .menu_section ul li a svg{
    font-size: 16px;
    color: #5C5F62;
    margin-right: 15px;
  }
  .setting_page .setting_menu_section .menu_section ul li a p{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0px;
    color: #000;
  }
  .setting_page .setting_detail_section{
    margin-left: 30px;
    width: 78%;
  }
  .setting_page .setting_detail_section .main_heading{
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    color: #000;
    padding: 20px 0px;
    display: flex;
    font-family: 'Inter', sans-serif !important;
  }
  .setting_page .setting_detail_section .main_heading h2{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    font-family: 'Inter', sans-serif !important;
  }
  .setting_page .setting_detail_section .mybutton{
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter', sans-serif !important;
    background-color: #2BB673;
    color: #fff;
  }

  .setting_page .setting_detail_section .mybutton:hover {
    background-color: #e2e8f0;    
  }
  .page_main_heading{
    font-size: 32px;
    line-height: 38px;
    margin: 0px;
    color: #000000;
     font-family: 'Inter', sans-serif !important;
     font-weight: 300;
  }



/*Pagination Css Style*/

.pagination {
  padding-top: 30px;
  margin: 0px;
  list-style-type: none;
}
.pagination li{
  display: inline-block;
}
.pagination li a{
  padding: 5px 20px;
  display: block;
  border: 1px solid #ccc;
  color: #000 !important;
}
.pagination li a:hover{
  color: #1400FF !important;
}
.pagination li:first-child a{
  border-radius: 5px 0px 0px 5px;
}
.pagination li:last-child a{
  border-radius: 0px 5px 5px 0px;
}

.pagination .page-item.active,.page-link .page-link.active{
  background-color: #F5F5F5;
  border: 1px solid #ccc;
  color: black;
}

/*Customer Detail Css*/

.customer_main_data_section{
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px 0px;
  display: unset;
  margin-bottom: 20px;
  overflow-x: scroll;
}
.customer_main_data_section .customer_box{
  border-right: 1px solid #ccc;
  padding: 10px 15px 10px 15px;
}
.customer_main_data_section .customer_box:last-child{
  border-right: 0px;
}
.customer_main_data_section .customer_box h2{
  font-size: 16px;
  line-height: 21px;
  color: #000;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin: 0px 0px 5px 0px;
  padding-right: 40px;
}

.customer_main_data_section .customer_box p{
  font-size: 12px;
  line-height: 16px;
  color: #7C7C7C;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.customer_tabs_section{
  border: 1px solid #ccc;
  border-radius: 10px;
}
.customer_tabs_section .customer_tabs_head_section{
  background-color: #2BB673;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 0px 30px;
}
.customer_tabs_section .customer_tabs_head_section button{
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  font-family: "Inter", sans-serif;
  padding: 10px 20px;
  border-bottom: 4px solid transparent;
}
.customer_tabs_section .customer_tabs_head_section .user_add_button{
  position: absolute;
  right: 30px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  padding: 5px 20px;
  height: unset;
  color: #000;
  top: 6px;
}
.customer_tabs_section .customer_tabs_head_section button[aria-selected="true"]{
  border-bottom: 4px solid #fff;
}
.customer_tabs_section .analytics_panel_section .analytics_box_section{
  border: 1px solid #ccc;
  padding: 15px;
  width: 273px;
  border-radius: 5px;
  margin-right: 20px;
}
.customer_tabs_section .analytics_panel_section .analytics_box_section h2{
  font-size: 32px;
  line-height: 38px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px 0px 5px 0px;
}

.customer_tabs_section .analytics_panel_section .analytics_box_section p{
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.customer_tabs_section .analytics_panel_section .analytics_box_section span{
  font-size: 10px;
  line-height: 13px;
  color: #7C7C7C;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.customer_tabs_section .analytics_panel_section .chart_box{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
}
.customer_tabs_section .analytics_panel_section .chart_box .highcharts-title{
  font-size: 16px !important;
  line-height: 21px !important;
  color: #000 !important;
  font-family: "Inter", sans-serif !important;
  margin: 0px 0px 20px !important;
  font-weight: 700 !important;
}
.customer_tabs_section .customer_transaction_panel{
  padding: 0px;
}
.customer_tabs_section .customer_transaction_panel .table_section .chakra-table__container .chakra-table{
  border: 0px;
}

/*Customer Detail Css*/



/*Dashboard CSS start*/
.dashboard_panel_section{
  padding: 20px 70px;
}
.dashboard_panel_section .analytics_panel_section .analytics_box_section{
  border: 1px solid #ccc;
  padding: 15px;
  width: 273px;
  border-radius: 5px;
  margin-right: 20px;
}
.dashboard_panel_section .analytics_panel_section .analytics_box_section h2{
  font-size: 32px;
  line-height: 38px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px 0px 5px 0px;
}

.dashboard_panel_section .analytics_panel_section .analytics_box_section p{
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.dashboard_panel_section .analytics_panel_section .analytics_box_section span{
  font-size: 10px;
  line-height: 13px;
  color: #7C7C7C;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.dashboard_panel_section .analytics_panel_section .chart_box{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 30px;
}
.chart_title_box_section{
  margin-right: 20px;
}
.chart_title_box_section:last-child{
  margin-right: 0px;
}
.chart_title_box_section .chart_title_box{
  width: 20px;
  height: 20px;
}
.chart_title_box_section p{
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  margin: 0px;
}
.highcharts-credits{
  display: none;
}
/*Dashboard CSS End*/



.token.property{
  color: #DF3079 !important;
}
